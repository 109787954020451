.card__container {
  touch-action: manipulation;
  position: relative;
  margin-right: 5%;
  margin-left: 5%;
  margin-bottom: 40px;
  z-index: 0;
}

.card__info__container {
  width: 100%;
  color: #fe7b7b;
  order: 1;
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  gap: 10px;
  margin-top: 20px;
}

.card__title__container {
  display: flex;
  align-items: baseline;
  gap: 5px;
}

.card__title__container>*,
.card__title__container>*>* {
  margin: 0;
}

/* SWIPER -------- */
.swiper-container {
  width: 100%;
  position: initial;
  display: flex;
  flex-direction: column;
}

.project__img {
  width: 100%;
  height: auto;
}

.swiper-slide {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}

.custom__pagination {
  display: flex;
  gap: 7px;
}

.swiper-pagination-bullet {
  background-color: #fe7b7b;
  width: 7px;
  height: 7px;
  border-radius: 0;
}

.swiper-container-horizontal>.swiper-pagination-bullets {
  bottom: 0;
  right: 0;
  width: auto;
  text-align: end;
}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 2px;
}

.swiper-container-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet:last-child {
  margin-right: 0;
}

.swiper-pagination-bullet-active {
  background-color: #fefe00;
  box-shadow: 0 0 5px #fff, 0 0 20px rgba(254, 254, 0, 0),
    0 0 10px rgba(254, 254, 0, 0);
}

.swiper-button-prev,
.swiper-button-next {
  display: none;
}