.infos__container {
  font-family: "Inknut Antiqua", serif;
  font-weight: 300;
  color: #fe7b7b;
  padding: 0 20px;
  margin: 0 auto;
}

.infos__container>* {
  margin-bottom: 20px;
}

.infos__container>*:last-child {
  margin-bottom: 0;
}

.infos__mail__link {
  color: #fe7b7b;
}

.dum__space__contact {
  height: 20px;
}