.bottom__bar__container {
  font-family: "Righteous", sans-serif;
  font-weight: 400;
  display: flex;
  align-items: center;
  height: 40px;
  background: #fe7b7b;
  color: black;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: 2;
}

.bottom__flex__wrap {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-right: 10px;
  margin-left: 10px;
}

.bottom__flex__wrap>* {
  margin: 0;
  cursor: pointer;
}