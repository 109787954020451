.top__bar__container {
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fefe00;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}

.emoji__rail {
  display: flex;
  position: absolute;
  top: -15px;
  margin-top: 0px;
  height: 55px;
  width: 60px;
  padding-right: 50px;
  padding-left: 0px;
  overflow: hidden;
}

.emoji__rail img {
  width: 70px;
  height: 70px;
  animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}