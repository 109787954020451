.ticker__container {
  width: 100%;
  z-index: -1;
}

.ticker__text {
  font-family: "Righteous", sans-serif;
  font-weight: 400;
  width: max-content;
  font-weight: 400;
  color: #fe7b7b;
  /* font-size: 23px; */
  margin: 0;
}